/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from "react";
import Prismic from "@prismicio/client";
import { Client } from "../";

interface fetchProps {
  url: string;
}

const fetch = ({ url }: fetchProps) => {
  const [doc, setDocData] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Client.query(
          Prismic.Predicates.at("document.tags", [url]),
          { pageSize: 100 }
        );
        if (response) {
          setDocData(response.results);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { doc, isLoading };
};

export default fetch;
