import { Button, Box, Center, VStack } from "@chakra-ui/react";
import React from "react";

import styles from "./Greeting.module.css";

interface GreetingProps {
  data: any;
}
const Greeting = ({ data }: GreetingProps) => {
  return (
    <div>
      <Center
        style={{
          position: "absolute",
          zIndex: 2,
          width: "100%",
          height: "100vh",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <VStack spacing={4}>
          <Box style={{ fontSize: "50px", color: "red" }}>
            {data.primary.title[0].text}
          </Box>
          <Box style={{ fontSize: "25px", color: "red" }}>
            {data.primary.text[0].text}
          </Box>
          <Box>
            <Button colorScheme="teal" size="md">
              {data.primary.btn[0].text}
            </Button>
          </Box>
        </VStack>
      </Center>
      <video autoPlay loop muted id="video-id" className={styles.video}>
        <source src={data.primary.video.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* <video id="video-id" className={styles.video}>
        <source src={data.primary.video.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
    </div>
  );
};

export default Greeting;
