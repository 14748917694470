import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Button, Stack, Image } from "@chakra-ui/react";

import styles from "./Header.module.css";

interface HeaderProps {
  data: any;
}
const Header = ({ data }: HeaderProps) => {
  return (
    <Container
      fluid
      // Only works inline for some reason.
      style={{
        zIndex: 4,
        position: "absolute",
        width: "100%",
        padding: "20px 20px 0px 20px",
      }}
    >
      {data.length !== 0 && (
        <Row>
          <Col xs={6} style={{ color: "red" }} className={styles.img}>
            <Image
              boxSize="100px"
              objectFit="cover"
              src={data.logo.url}
              alt="Segun Adebayo"
            />
          </Col>
          <Col xs={6} className={styles.btn}>
            <Stack spacing={4} direction="row">
              <Button colorScheme="teal" size="md">
                {data.btn1[0].text}
              </Button>
              <Button colorScheme="teal" size="md">
                {data.btn2[0].text}
              </Button>
            </Stack>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Header;
