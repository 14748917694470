import { Box, HStack, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { Motion } from "../../../../components";

import styles from "./CallToAction.module.css";

interface CallToActionProps {
  data: any;
}

const CallToAction = ({ data }: CallToActionProps) => {
  const [isSmall] = useMediaQuery("(max-width: 750px)");
  var size = 12 / data.items.length;
  if (size % 2 !== 0) {
    size = 4;
  }
  return (
    <Motion threshold={0.7}>
      <Container className={styles.root}>
        <Row>
          {data.items.map((data: any, index: number) => (
            <Col md={size} className={isSmall ? "" : styles.col} key={index}>
              <HStack spacing="24px">
                <Box className={styles.img}>
                  <img src={data.img.url} alt={data.img.alt} />
                </Box>
                <Box>
                  <Box className={styles.title}>
                    <h3>{data.title[0].text}</h3>
                  </Box>
                  <Box className={styles.text}>
                    <p>{data.text[0].text}</p>
                  </Box>
                </Box>
              </HStack>
            </Col>
          ))}
        </Row>
      </Container>
    </Motion>
  );
};

export default CallToAction;
