import React from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

type MotionType = "up" | "down" | "left" | "right";
type MotionTypes = {
  [Type in MotionType]: {
    initial?: { x: number; opacity: number } | { y: number; opacity: number };
    animate?: { x: number; opacity: number } | { y: number; opacity: number };
  };
};

const motionList: MotionTypes = {
  up: {
    initial: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  },
  down: {
    initial: { y: -30, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  },
  left: {
    initial: { x: 30, opacity: 0 },
    animate: { x: 0, opacity: 1 },
  },
  right: {
    initial: { x: -30, opacity: 0 },
    animate: { x: 0, opacity: 1 },
  },
};

interface PropTypes {
  type?: MotionType;
  threshold?: number;
  children: React.ReactNode;
}

export const Motion = ({
  type = "up",
  threshold = 0.35,
  children,
}: PropTypes) => {
  return (
    <InView threshold={threshold}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial={motionList[type].initial}
          animate={inView ? motionList[type].animate : { opacity: 0 }}
          transition={{ type: "spring", stiffness: 40 }}
        >
          {children}
        </motion.div>
      )}
    </InView>
  );
};

export default Motion;
