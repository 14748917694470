import { Box, VStack, HStack, useMediaQuery, Center } from "@chakra-ui/react";
import React from "react";
import { Container } from "react-grid-system";

import styles from "./Journey.module.css";
import { Motion } from "../../../../components";

interface JourneyProps {
  data?: any;
}
const Journey = ({ data }: JourneyProps) => {
  const [isSmall] = useMediaQuery("(max-width: 750px)");
  return (
    <Motion threshold={0.35}>
      <Container>
        <Center>
          <h1 style={{ fontSize: "30px", padding: "40px 0px 40px 0px" }}>
            SVONA VIRKAR FERLIÐ:
          </h1>
        </Center>
        <Center>
          <VStack spacing={12}>
            {isSmall ? (
              <Box>
                {data.items.map((data: any, index: number) => (
                  <Box key={index}>
                    <Box>{data.title[0].text}</Box>
                    <Box>
                      <img
                        src={data.img.url}
                        alt={data.img.alt}
                        className={styles.img}
                      />
                    </Box>
                    <Box>{data.text[0].text}</Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box>
                {data.items.map((data: any, index: number) => (
                  <HStack
                    key={index}
                    style={{
                      paddingLeft: data.offset.toString() + "%",
                      paddingBottom: "20px",
                    }}
                    spacing={12}
                  >
                    <Box className={styles.title}>{data.title[0].text}</Box>
                    <Box className={styles.img}>
                      <img src={data.img.url} alt={data.img.alt} />
                    </Box>
                    <Box className={styles.text}>{data.text[0].text}</Box>
                  </HStack>
                ))}
              </Box>
            )}
          </VStack>
        </Center>
      </Container>
    </Motion>
  );
};

export default Journey;
