import React from "react";
import { Header, Loading } from "../../components";
import StickyBox from "react-sticky-box";

import { CallToAction, Greeting, Journey } from "./components";
import fetch from "../../prismic/fetch/fetch";
import { getHomeValues } from "../../utils/";
import { Box } from "@chakra-ui/react";
import styles from "./Home.module.css";

const Home = () => {
  const { doc, isLoading } = fetch({ url: "Home" });
  const tempmain = "#f8f8f8";
  const tempsec = "#ffffff";

  if (isLoading) {
    return <Loading />;
  }
  var [HeaderProps, HomeProps, FooterProps] = getHomeValues(doc);

  return (
    <>
      <StickyBox className={styles.sticky}>
        <Header data={HeaderProps} />
      </StickyBox>

      {HomeProps.map((data: any, index: number) => (
        <Box key={index}>
          {data.slice_type === "greeting" && (
            <Box>
              <Greeting data={data} />
            </Box>
          )}
          {data.slice_type === "call_to_action" && (
            <Box style={{ backgroundColor: tempmain }}>
              <CallToAction data={data} />
            </Box>
          )}
          {data.slice_type === "journey" && (
            <Box style={{ backgroundColor: tempsec }}>
              <Journey data={data} />
            </Box>
          )}
        </Box>
      ))}
    </>
  );
};

export default Home;
